import * as React from 'react';
import { useEffect, useState, useRef, useMemo } from 'react';
import cx from 'classnames';
import history from 'utils/history';
import Variation from 'constant/variation';
import { sendEventLog, SEND_PROJ_TARGET } from 'utils/analytics';
import SearchResult from 'components/SearchResult/SearchResult';
import useSearch from 'hooks/search';
import useDevice from 'hooks/device';
import useAuth, { getProfile } from 'hooks/auth';
import sendDataLayer from 'fe-common-library/dest/utils/sendDataLayer';
import styles from './search-bar.scss';

const SEARCH_BAR_HEIGHT = 34;
const SEARCH_ITEM_HEIGHT = 46;

interface SearchBarProps {
  searchCompletedCb?: VoidFunction;
  setId?: (id: string) => void;
}

function SearchBar({ searchCompletedCb, setId }: SearchBarProps) {
  const screenHeight = window.innerHeight;
  const [] = useAuth();
  const [device] = useDevice();
  const isMobile = false && device.layout === 'mobile';
  const input = useRef<HTMLInputElement | null>(null);
  const searchContext = useRef<SearchContext>({
    keyword: '',
    next: () => {},
  });
  const [result, search, clearSearch, isSearching] = useSearch();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  /**
   * Reset search result, use this function when a searching
   * is finished. The search result window will become invisible
   * after calling this function.
   */
  function reset() {
    searchContext.current = {
      keyword: '',
      next: () => {},
    };
    if (input.current) {
      input.current.value = '';
    }
    clearSearch();
    setSelectedIndex(0);
    if (searchCompletedCb) {
      searchCompletedCb();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (input.current) {
        input.current.focus();
      }
    }, 500);
  }, []);

  function handleSelect(num: number) {
    setSelectedIndex(Math.max(selectedIndex - 1, num));
    if (result.length < selectedIndex + 3) {
      searchContext.current.next();
    }
  }

  async function handleInputChange() {
    if (input.current) {
      const validCode = input.current.value.replace(/\s/g, '');

      if (!input.current.value) {
        reset();
      } else if (validCode) {
        searchContext.current = {
          keyword: validCode,
          next: await search(validCode),
        };
      } else {
        reset();
      }
    }
  }

  function handleSubmit(resultIndex?: number) {
    const symbol = resultIndex ? result[resultIndex].symbol : result[selectedIndex].symbol;

    sendEventLog(Variation.stockChannelName, '搜尋', searchContext.current.keyword, {}, SEND_PROJ_TARGET);
    sendDataLayer({
      dataPrefix: SEND_PROJ_TARGET,
      eventName: 'Click_Search',
      clickItem: searchContext.current.keyword,
    });

    if (symbol) {
      history.getHistory().push(`/market/${symbol}`);
      if (setId) {
        setId(symbol);
      }
    }
    reset();
    if (searchCompletedCb) {
      searchCompletedCb();
    }
  }

  async function handleKey(e: React.KeyboardEvent) {
    const hasSearchWindow = result.length > 0;

    if (hasSearchWindow) {
      switch (e.keyCode) {
        // Up
        case 38:
          e.preventDefault();
          handleSelect(selectedIndex - 1);
          return;
        // Down
        case 40:
          e.preventDefault();
          handleSelect(selectedIndex + 1);
          return;
      }
    }

    if (e.keyCode === 13) {
      handleSubmit();
      return;
    }
  }

  const height = result.length > 0 ? SEARCH_ITEM_HEIGHT * result.length : 0;
  return (
    <div
      className={cx(styles['search-bar-container'], {
        [styles.mobile]: isMobile,
      })}
    >
      <div
        className={cx(styles['search-bar-container-inner'], {
          [styles.mobile]: isMobile,
        })}
      >
        <div className={cx(styles.searchbox, { [styles.mobile]: isMobile }, 'row')}>
          <img alt="搜尋股票" className={styles['search-icon']} src={require('assets/icon-search.svg')} />
          <input
            ref={input}
            type="search"
            className={styles['search-input']}
            placeholder={`搜尋股票名稱或代碼${!!getProfile() ? '' : '，登入可自選組合'}`}
            onBlur={() => setTimeout(reset, 500)}
            onChange={handleInputChange}
            onKeyDown={handleKey}
          />
          {useMemo(
            () =>
              input.current &&
              input.current.value && <button aria-label="搜尋" onClick={reset} className={styles.clear} />,
            [input.current && input.current.value]
          )}
          {isSearching && <span className={styles.searching} />}
          <SearchResult
            zIndex={10}
            onClick={handleSubmit}
            onMouseEnter={handleSelect}
            onPageEnd={() => searchContext.current.next()}
            selectedIndex={selectedIndex}
            data={searchContext.current.keyword ? result || [] : []}
            position={isMobile ? 'fixed' : 'absolute'}
            top={isMobile ? SEARCH_BAR_HEIGHT * 2 : SEARCH_BAR_HEIGHT}
            height={height}
            maxHeight={isMobile ? screenHeight : Math.min(result.length * 45, 250)}
          />
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
