import React from 'react';

const Chatroom = () => {
  const aink = window.location.href;
  const iframeSrc = `https://hao.cnyes.com/talk/?aink=${aink}&use=postmessage`;

  return <iframe height="100%" src={iframeSrc} />;
};

export default Chatroom;
