import * as React from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames';
import NaviMenu from 'components/NaviMenu/NaviMenu';
import AccountPanel from 'fe-common-library/dest/components/AccountPanel';
import 'fe-common-library/dest/components/AccountPanel/style.css';
import SourceModal from 'components/SourceModal/SourceModal';
import { SEND_PROJ_TARGET, setUserId } from 'utils/analytics';
import Variation from 'constant/variation';
import globalConfig from 'constant/globalConfig';
import useAuth, { getProfile, isLogin } from 'hooks/auth';
import useNotify from 'hooks/notify';
import useDevice from 'hooks/device';
import gaDataset from 'fe-common-library/dest/utils/gaDataset';
import styles from './UserPanel.scss';

function UserPanel() {
  const [device] = useDevice();
  const [isSourceModalEnabled, setIsSourceModalEnabled] = useState<boolean>(false);
  const userProfile = getProfile();
  const [auth, login, logout] = useAuth();
  const [notify] = useNotify();
  const [toggleNavi, setToggleNavi] = useState(false);
  const [toggleUserPanel, setToggleUserPanel] = useState(false);
  const highlights = Object.keys(notify).filter(key => notify[key]);
  const showHighlight = !isLogin() || highlights.length > 0;
  const accountProfile = userProfile || {};

  function handleToggleNavi(e) {
    e.preventDefault();
    setToggleNavi(!toggleNavi);
  }

  function handleToggleUserPanel() {
    setToggleUserPanel(!toggleUserPanel);
  }

  useEffect(() => {
    if (auth && auth.authorization) {
      const nextProfile = getProfile();
      if (nextProfile) {
        setUserId(nextProfile.uid);
      }
    } else {
      setUserId(null); // remove userId
    }
  }, [auth]);

  function showSourceModal(e) {
    e.preventDefault();
    setIsSourceModalEnabled(true);
  }

  function closeSourceModal(e) {
    e.preventDefault();
    setIsSourceModalEnabled(false);
  }

  const dropdownMenuList = [
    {
      title: '會員條款',
      redirect: {
        target: '_blank',
        href: '/agreement',
      },
      gaEvent: `${Variation.stockChannelName},info click,會員條款,proj`,
    },
    {
      title: '資料來源',
      onClickCallBack: showSourceModal,
      gaEvent: `${Variation.stockChannelName},info click,資料來源,proj`,
    },
    {
      title: '意見回饋',
      redirect: {
        target: '_blank',
        href: `https://docs.google.com/forms/d/1QvhPSjjG-vrxPUvpzGd2uYmFiIs1cMu1luFAwSonm6U?usp=pp_url&entry.1312867554=${
          document.location.href
        }`,
      },
      gaEvent: `${Variation.stockChannelName},info click,意見回饋,proj`,
    },
    {
      title: '版本',
      // @ts-ignore
      onClickCallBack: () => alert(`${env.build}`),
      gaEvent: `${Variation.stockChannelName},info click,版本,proj`,
    },
  ];

  const onClickLogin = () => {
    if (!isLogin()) {
      login();
    }
  };

  const onClickLogout = () => {
    logout();
    window.location.reload();
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={cx('row', styles['panel-wrapper'], {
          [styles.mobile]: device.layout === 'mobile',
        })}
      >
        {!userProfile && (
          <div
            className={cx(styles['login-wrapper'], {
              [styles.highlight]: showHighlight,
            })}
            onClick={handleToggleUserPanel}
            data-anue-ga-event={`${Variation.stockChannelName},會員 click,登入,proj`}
            data-tid="account-login"
            {...gaDataset({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click_Member',
              clickItem: '登入',
            })}
          >
            <span
              className={styles['auth-status']}
              onClick={handleToggleUserPanel}
              data-anue-ga-event={`${Variation.stockChannelName},會員 click,登入,proj`}
              style={{
                pointerEvents: 'none',
              }}
            >
              登入
            </span>
          </div>
        )}
        {userProfile && (
          <div>
            <div
              className={cx(styles['logout-wrapper'], {
                [styles.highlight]: showHighlight,
              })}
              onClick={handleToggleUserPanel}
              data-tid="account-logout"
            >
              <figure className={styles.avatar} style={{ backgroundImage: `url(${userProfile.avatar})` }} />
              <span className={styles['user-name']}>{userProfile.name || '鉅亨網使用者'}</span>
            </div>
          </div>
        )}
        {/* {isLogin && <div className={styles['vertical-bar']} />} */}
        {/* {isLogin &&
            <div
              className={styles['member-wrapper']}
              onClick={() => {
                window.open(`${authConfig.loginUrl}?member=1&ref=member`, '_blank');
              }}
            >
              會員中心
            </div>} */}
        <div className={styles['vertical-bar']} />
        <div className={styles['menu-icon']} onClick={handleToggleNavi} />
        <div
          className={cx(styles['dropdown-menu'], {
            [styles.display]: toggleNavi,
          })}
        >
          <NaviMenu list={dropdownMenuList} />
        </div>
      </div>
      <SourceModal isEnabled={isSourceModalEnabled} closeHandler={closeSourceModal} />
      <div className={styles['accountPanel-wrapper']}>
        <AccountPanel
          isOpen={toggleUserPanel}
          isLogin={isLogin()}
          login={onClickLogin}
          logout={onClickLogout}
          profile={accountProfile}
          onClosePanel={handleToggleUserPanel}
          highlights={highlights}
          cnyesBaseUrl={globalConfig.CnyesBaseUrl}
        />
      </div>
    </div>
  );
}

export default React.memo(() => <UserPanel />, () => true);
