import * as React from 'react';
import getty from 'anue-fe-sdk/getty';
import { useRef, useCallback, useState, useEffect } from 'react';
import { isLogin, login } from 'hooks/auth';
import history from 'utils/history';
import { sendEventLog, SEND_PROJ_TARGET } from 'utils/analytics';
import Variation from 'constant/variation';
import styles from './quote-item.scss';
import numFormat from 'utils/num-format';
import sendDataLayer from 'fe-common-library/dest/utils/sendDataLayer';

interface QuoteItemProps extends Quote {
  onDrop: (target: string, after: string) => void;
  onRemove: (symbol: string) => void;
  isEmpty?: boolean;
  currentGroup: number;
  setId: React.Dispatch<React.SetStateAction<string>>;
}

let dragging = '';
let lastOver = '';

function getColor(direction) {
  switch (direction) {
    case 1:
      return styles.up;
    case 2:
      return styles.down;
    case 3:
      return styles['animate-up'];
    case 4:
      return styles['animate-down'];
    default:
      return '';
  }
}

export default React.memo(
  function QuoteItem(props: QuoteItemProps) {
    const cxQuote = getColor(props.direction);
    const { isEmpty, setId } = props;
    const [enable, setEnable] = useState<boolean>(true);
    const el = useRef<HTMLDivElement | null>(null);
    const lastDisplayedPrice = useRef<number>(-1);
    const isValidSymbol = !!getty(props, ['name']);

    // highlight quote labels when price changing
    useEffect(() => {
      const container = el.current;
      if (container && lastDisplayedPrice.current > 0) {
        const next = styles[props.ch > 0 ? 'animate-up' : 'animate-down'];
        const targets = container.querySelectorAll(`.${styles.price}`) || [];

        targets.forEach(e => (e.className = `${e.className.replace(next, '')} ${next}`));

        setTimeout(() => {
          targets.forEach(e => (e.className = e.className.replace(next, '')));
        }, 500);
      }
      lastDisplayedPrice.current = props.ch;
    }, [props.ch]);

    function onDragStart(e: React.DragEvent<HTMLDivElement>) {
      if (el.current) {
        lastOver = '';
        dragging = props.symbol;
        el.current.className += ` ${styles.drag}`;
      }
    }

    function onDragEnd(e: React.DragEvent<HTMLDivElement>) {
      if (el.current) {
        dragging = '';
        el.current.className = el.current.className.replace(styles.drag, '');
      }
      if (props.symbol !== lastOver) {
        props.onDrop(props.symbol, lastOver);
      }
    }

    function onDragEnter(e: React.DragEvent<HTMLDivElement>) {
      lastOver = isEmpty ? 'dummy' : props.symbol;
      if (el.current && dragging !== props.symbol) {
        el.current.className += ` ${styles.over}`;
      }
    }

    function onDragLeave() {
      if (el.current) {
        el.current.className = el.current.className.replace(styles.over, '');
      }
    }

    const handleClick = useCallback(
      e => {
        e.preventDefault();
        if (isEmpty) {
          return;
        }
        // history.getHistory().replace(`/market/${props.symbol}`);
        setId(props.symbol);
      },
      [props.symbol]
    );

    function onRemove(e) {
      if (isLogin()) {
        setEnable(false);
        setTimeout(() => {
          props.onRemove(props.symbol);
          sendEventLog(Variation.stockChannelName, '投資組合清單 click', '移除', {}, SEND_PROJ_TARGET);
          sendDataLayer({
            dataPrefix: SEND_PROJ_TARGET,
            eventName: 'Click_投資組合',
            section: '投資組合',
            clickItem: `${Number(props.currentGroup) + 1}_${props.symbol}_${props.name}`,
          });
        }, 500);
      } else {
        login('login_plus');
        sendEventLog(Variation.stockChannelName, '投資組合清單 click', '登入', {}, SEND_PROJ_TARGET);
        sendDataLayer({
          dataPrefix: SEND_PROJ_TARGET,
          eventName: 'Click_投資組合',
          section: '投資組合',
          clickItem: '登入',
        });
      }
      e.stopPropagation();
    }

    function getName() {
      if (props.symbol) {
        return String(props.symbol).split(':')[1];
      }
      return '';
    }

    function parsePriceStr(priceArr, emptyDataStr = '--') {
      const foundPriceStr = priceArr.find(price => price !== emptyDataStr);

      return foundPriceStr || emptyDataStr;
    }

    return isValidSymbol || isEmpty ? (
      <div
        data-anue-ga-event={`${Variation.stockChannelName},投資組合清單 click,${props.currentGroup + 1}_${
          props.symbol
        },proj`}
        data-enable={enable}
        onClick={handleClick}
        ref={el}
        draggable
        title={isEmpty ? undefined : `${props.name}(${getName()})`}
        className={`row ${styles.container}`}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        style={{
          opacity: isEmpty ? 0 : undefined,
          cursor: isEmpty ? 'default' : 'pointer',
        }}
      >
        <div className={styles.name}>{props.name}</div>
        <div className={`${styles.price} ${cxQuote}`} style={{ width: 70 }}>
          {parsePriceStr([props.lp, props.prevClosePrice])}
        </div>
        <div className={`${styles.short} ${styles.price} ${cxQuote}`}>{props.ch}</div>
        <div className={`${styles.short} ${styles.price} ${cxQuote}`}>{props.chp}%</div>
        <img src="/assets/icon-delete-stock.svg" alt="移除" onClick={onRemove} />
      </div>
    ) : null;
  },
  (prev, next) =>
    prev.symbol === next.symbol &&
    prev.ch === next.ch &&
    prev.name === next.name &&
    prev.onDrop === next.onDrop &&
    prev.onRemove === next.onRemove &&
    prev.direction === next.direction
);
