import * as React from 'react';
import { useState } from 'react';
import { AdSlot } from 'cnyes-dfp';
import cx from 'classnames';
import Variation, { isTwStockChannel } from 'constant/variation';
import globalConfig from 'constant/globalConfig';
import ShareFB from 'components/ShareFB/ShareFB';
import AdProfiles from 'components/DFP/AdProfiles';
import SearchBar from 'containers/SearchBar/SearchBar';
import UserPanel from 'containers/UserPanel/UserPanel';
import NaviMenu from 'components/NaviMenu/NaviMenu';
import styles from './Header.scss';

const { twStockUrl, globalStockUrl } = globalConfig;
const dropdownMenuList = [
  isTwStockChannel
    ? {
        title: '國際版股市Talk',
        img: '/assets/icon-global-stock-talk.svg',
        redirect: {
          href: globalStockUrl,
        },
        gaEvent: '台股股市Talk,切換頻道 click,國際版股市Talk,proj',
      }
    : {
        title: '台股股市Talk',
        img: '/assets/icon-stock-talk-tw.svg',
        redirect: {
          href: twStockUrl,
        },
        gaEvent: '國際版股市Talk,切換頻道 click,台股股市Talk,proj',
      },
];

function Header({ symbol, setId }) {
  const [toggleNavi, setToggleNavi] = useState(false);

  function handleToggleNavi() {
    setToggleNavi(!toggleNavi);
  }

  return (
    <header className={`${styles.container} row`}>
      <a
        href="https://www.cnyes.com/"
        className={styles.logo}
        data-anue-ga-event={`${Variation.stockChannelName},logo click,home,proj`}
      />
      <a
        href="/"
        className={cx(styles['logo-talk'], {
          [styles['is-tw-stock']]: isTwStockChannel,
        })}
      />
      <div
        className={cx(styles['switch-channel'], {
          [styles.display]: toggleNavi,
        })}
        onClick={handleToggleNavi}
      >
        <div className={styles['channel-options']}>
          <NaviMenu list={dropdownMenuList} />
        </div>
      </div>
      <div style={{ flex: 0.5 }} />
      <div className={styles.share}>
        <ShareFB symbol={symbol} />
      </div>
      <SearchBar setId={setId} />
      <div style={{ flex: 1 }} />
      {isTwStockChannel && (
        <div className="row">
          <AdSlot profile={AdProfiles.adSetBesideSearchBarA} lazyLoading={false} />
          <AdSlot profile={AdProfiles.adSetBesideSearchBarB} lazyLoading={false} />
        </div>
      )}
      <UserPanel />
    </header>
  );
}

export default React.memo(
  (props: { symbol: string; setId?: (id: string) => void }) => <Header symbol={props.symbol} setId={props.setId} />,
  (prev, next) => prev.symbol === next.symbol
);
