import * as React from 'react';
import { useState, useEffect } from 'react';
import useDevice from 'hooks/device';
import useUI, { dispatch } from 'hooks/ui';
import Header from 'widgets/Header';
import AdProfiles from 'components/DFP/AdProfiles';
import News from 'widgets/News/News';
import Variation, { isTwStockChannel } from 'constant/variation';
import Portfolio from 'widgets/Portfolio';
import SymbolInfo from 'containers/SymbolInfo/SymbolInfo';
import StockPanel from 'containers/StockPanel/StockPanel';
import VideoBlock from 'components/VideoBlock/VideoBlock';
import { useHaoPostMessage } from 'hooks/haoPostMessage';

import styles from './MarketApp.scss';
import Chatroom from 'containers/ChatroomV2';

declare global {
  interface Window {
    liveprocess?: any;
  }
}

interface MarketAppProps {
  match: {
    params: {
      id: string;
      tab?: string;
    };
  };
  location: {
    query: {
      chartOnly: string;
    };
    key: string;
    pathname: string;
    search: string;
    hash: string;
    state: any;
  };
  RightSide: React.Component;
  children: any;
}

function MarketApp(props: MarketAppProps) {
  const [info] = useDevice();
  const [ui] = useUI();
  const [showAside, setShowAside] = useState(true);
  const { location, children } = props;
  const symbol = useHaoPostMessage();
  const [id, setId] = useState<string>(symbol || props.match.params.id || Variation.quote.defaultSymbol);

  useEffect(() => {
    if (symbol) {
      setId(symbol);
    }
  }, [symbol]);

  if (!id) {
    console.log('Market app render blank page since the id is empty %o', id);

    return null;
  }

  useEffect(() => {
    const currentUrl = new URL(window.location.href);

    const pathSegments = currentUrl.pathname.split('/');
    pathSegments[pathSegments.length - 1] = id;
    currentUrl.pathname = pathSegments.join('/');

    window.history.replaceState(null, '', currentUrl.toString());
  }, [id]);

  useEffect(() => {
    // @ts-ignore
    if (typeof googletag !== 'undefined' && googletag.pubads) {
      // @ts-ignore
      const ads = googletag.pubads();
      if (ads) {
        const adSlot = document.querySelector<HTMLDivElement>('#chat-list-ad');
        if (adSlot) {
          adSlot.style.opacity = '0';
          setTimeout(() => {
            const map = ads.getSlotIdMap();
            ads.refresh([map[`${AdProfiles.nativeAdStockTalkChatroom.path}_0`]]);
          }, 1000);
          setTimeout(() => {
            adSlot.style.opacity = '1';
          }, 1500);
        }
      }
    }
  }, [id]);

  useEffect(() => {
    // get live video on page start
    if (isTwStockChannel) {
      dispatch({
        type: 'video',
        payload: {
          url: '',
          channelTitle: 'LiTv 線上影視',
          isLiTV: true,
          visible: true,
        },
      });
    }
  }, []);

  /**
   * show/hide left side panel.
   */
  function toggleLeftPanel() {
    setShowAside(!showAside);
    // when toggling the panel also dispatch a window `resize` event
    // since HighCharts in StockPanel only resize when `resize` event triggered
    if (info.isIE) {
      const resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    } else {
      window.dispatchEvent(new Event('resize'));
    }
  }

  if (location.query && location.query.chartOnly) {
    return (
      <div>
        <section className={styles['middle-section']}>
          <SymbolInfo symbol={id} />
          <StockPanel symbol={id} main={children} />
        </section>
      </div>
    );
  }

  return (
    <div>
      <Header symbol={id} setId={setId} />
      <div className={styles.market} style={{ maxHeight: info.height - 52 }}>
        <aside
          className={styles['aside-left']}
          style={
            showAside
              ? {}
              : {
                  width: 15,
                }
          }
        >
          <Portfolio setId={setId} />
          <News symbol={id} />
        </aside>
        <section className={styles['middle-section']}>
          <svg
            onClick={toggleLeftPanel}
            data-anue-ga-event={`${Variation.stockChannelName},行情 click,收合,proj`}
            className={styles.toggle}
            height="20"
            width="20"
            viewBox="0 0 24 24"
          >
            <path
              stroke="#FFF"
              strokeWidth="2"
              d={showAside ? 'M 14 18 L 10 12 L 14 6' : 'M 6 8 L 18 8 M 6 12 L 18 12 M 6 16 L 18 16'}
              fill="transparent"
            />
          </svg>
          <SymbolInfo symbol={id} />
          <StockPanel symbol={id} main={children} />
        </section>
        <aside className={styles['aside-right']}>
          <section className={styles.section}>
            <Chatroom />
          </section>
        </aside>
        {ui.video.visible && <VideoBlock video={ui.video} />}
      </div>
    </div>
  );
}

export default React.memo(
  (props: MarketAppProps) => <MarketApp {...props} />,
  (prev, next) => prev.location.pathname === next.location.pathname
);
